<template>
    <div class="wrapper_contact"
        v-if="this.page_settings"
    >

        <loading v-if="!this.$store.getters.loading" />

        <headerBg data-aos-duration="2000" data-aos-delay="200" :white="true" data-aos="fade-up"
            :subtitle="data.page_settings.contact_subtitle" :title="data.page_settings.contact_title"
            :description="data.page_settings.contact_description"
            :bg="'https://api.interchim.bg/storage/' + data.page_settings.contact_bg" />

        <div class="contact">

            <p class="subtitle">{{data.page.sub_title}}</p>
            <h3 class="title">{{data.page.title}}</h3>


            <product-wrapper :contact="true" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="2000">
                <product-item data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                    :img="'https://api.interchim.bg/storage/' + data.page_settings.phone_icon"
                    :title="data.page_settings.phone_number" :description="data.page_settings.number" />

                <product-item data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                    :img="'https://api.interchim.bg/storage/' + data.page_settings.location_icon"
                    :title="data.page_settings.our_address" :description="data.page_settings.adress" />

                <product-item data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                    :img="'https://api.interchim.bg/storage/' + data.page_settings.email_icon"
                    :title="data.page_settings.email_address" :description="data.page_settings.email" />

            </product-wrapper>

        </div>


        <div class="maps" v-html="data.page_settings.iframe_google">

        </div>


    </div>
</template>

<script>
    import loading from '../components/loading'
    import {
        RepositoryFactory
    } from "../api/RepositoryFactory";
    const GetRepository = RepositoryFactory.get('data');

    import productWrapper from "../components/product/productWrapper";
    import productItem from "../components/product/productItem";
    import headerBg from "../components/app/headerBg";

    export default {
        name: "Contact",
        components: {
            headerBg,
            productItem,
            productWrapper,
            loading
        },
        async mounted() {
            await this.fetch()
        },
        data() {
            return {
                isLoading: false,
                data: {},
                page_settings: '',
                title: null,
                meta: [],
                og: []
            }
        },

        watch: {
            '$route.params.slug': {
                immediate: true,
                handler() {
                    this.fetch()
                },
            },
        },

        methods: {
            fetch() {
                this.$store.dispatch('SET_LOADING_AXIOS', 2000)
                return new Promise((resolve, reject) => {
                    GetRepository.getContact()
                        .then(resp => {
                            this.isLoading = false;
                            this.data = resp.data.data;
                            this.page_settings = resp.data.data.page_settings;
                            this.title = this.data.page.meta_title;
                            this.og = this.data.meta.og.meta;
                            resolve(resp);
                        })
                        .catch(err => {
                            reject(err);
                        });
                });
            },
        },

        metaInfo() {
            return {
                title: this.title,
                meta: this.og
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/index";

    .contact {
        @include sectionGlobal;
        padding: 0 20px;


        & .subtitle {
            text-align: left !important;
            @include subtitle ($colorLightTitle);
        }

        & .title {
            max-width: 100% !important;
            text-align: left !important;
            margin: 20px 0 !important;
            @include title
        }

        & .product_wrapper {
            padding: 0 !important;
            margin: 0 auto 40px !important;
        }
    }

    .maps {
            width: 100%;
    }
</style>